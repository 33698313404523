import { Rive } from "@rive-app/canvas";

export default function initSplashScreen() {
    const canvasEl = document.querySelector('#logo-animation-canvas');

    if (!canvasEl) {
        return
    } else if (sessionStorage.getItem("splash_screen_shown") === 'true') {
        canvasEl.classList.add('opacity-0');
        document.querySelector('.splash-screen').classList.add('opacity-0', 'pointer-events-none', 'h-90%');
        return;
    }

    /* canvasEl.width = canvasEl.parentElement.offsetWidth;
    canvasEl.height = canvasEl.parentElement.offsetHeight; */

    let timerCounter = 0;
    let animationDone = false;
    const splashScreen = document.querySelector('.splash-screen');
    setInterval(myTimer, 500);

    function myTimer() {
        timerCounter += 500;
        (timerCounter >= 2500 && !splashScreen.classList.contains('opacity-0')) && canvasEl.classList.add('opacity-0');
        (timerCounter >= 3000 && !splashScreen.classList.contains('opacity-0')) && splashScreen.classList.add('opacity-0', 'pointer-events-none', 'h-90%');
    }

    const logoAnimation = new Rive({
        // Hosted .riv asset, or a local one. Uncomment line 8 to try with
        // a local basketball.riv asset, or add your own!
        src: "/wp-content/themes/salamandra-wp-theme/includes/salamandra_logo_animation.riv",
        // src: "basketball.riv",
        canvas: canvasEl,
        autoplay: true,
        onLoad: () => {
            logoAnimation.resizeDrawingSurfaceToCanvas();
            /* store "splash_screen_shown = true" in sessionStorage */
            sessionStorage.setItem("splash_screen_shown", true);
        },
    });
}