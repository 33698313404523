export default function initStickyListSection() {
    const stickyListSections = document.querySelectorAll('.sticky-list-section');

    stickyListSections.forEach(section => {
        const navBtns = section.querySelectorAll('.js-sticky-list-nav-btn');
        const scrollToPos = document.querySelector('.js-sticky-list-content').getBoundingClientRect().top + window.scrollY - 200;

        navBtns.forEach(navBtn => {
            navBtn.addEventListener('click', () => {
                const targetContent = document.querySelector(`#${navBtn.dataset.target}`);
                const activeContent = document.querySelector('.js-sticky-list-content.active');
                const activeBtn = document.querySelector('.js-sticky-list-nav-btn.active');

                if (activeContent.id != navBtn.dataset.target) {
                    activeBtn.classList.remove('active');
                    navBtn.classList.add('active');
                    activeContent.classList.remove('active');
                    !targetContent.classList.contains('active') && targetContent.classList.add('active');

                    window.scrollTo({
                        top: scrollToPos,
                        behavior: 'smooth',
                    });
                }
            })
        })
    })
}