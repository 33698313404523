/* SCROLL TO TOP BUTTON */
function initScrollToTopBtn() {
    const scrollToTopBtns = document.querySelectorAll('.js-scroll-to-top');
    scrollToTopBtns.forEach(btn => {
        btn.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    })
}

export default initScrollToTopBtn;