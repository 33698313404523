function displayLocalTime() {
    let timeContainers = document.querySelectorAll('.current-time');

    let intervalId = window.setInterval(function () {

        let currentTime = new Date();
        timeContainers.forEach(container => {
            container.innerHTML = currentTime.toLocaleTimeString("en-US", { timeZone: container.dataset.timezone, hour: '2-digit', minute: '2-digit' });
        })
    }, 1000);


}

export { displayLocalTime }