import MicroModal from 'micromodal'

function textModal() {

    MicroModal.init({
        openTrigger: "data-micromodal-trigger",
        closeTrigger: "data-micromodal-close",
        openClass: "is-open",
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: false,
        awaitCloseAnimation: false,
        onShow: modal => {
            modal.parentElement.querySelector('.modal-trigger-btn').setAttribute('aria-expanded', true);
        }, // [1]
        onClose: modal => modal.parentElement.querySelector('.modal-trigger-btn').setAttribute('aria-expanded', false), // [2]
    });
}

export { textModal };