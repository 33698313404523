function appearObserver(target) {
    function displayEntry(entry) {
        entry.isIntersecting && entry.target.classList.remove('opacity-0', 'translate-y-30');
    }

    let callback = function (entries, observer) {
        entries.forEach(entry => {
            displayEntry(entry);
        });
    };


    let options = {
        rootMargin: "0px 0px -20% 0px",
    }

    let observer = new IntersectionObserver(callback, options);

    observer.observe(target);
}

function heartObserver(target) {

    function displayEntry(entry) {
        if (entry.isIntersecting) {
            entry.target.classList.add('heart-icon-filled');
            observer.unobserve(target);
        }
    }

    let callback = function (entries, observer) {
        entries.forEach(entry => {
            displayEntry(entry);
        });
    };

    let options = {
        rootMargin: "0px 0px -20% 0px",
    }

    let observer = new IntersectionObserver(callback, options);

    observer.observe(target);
}

function scrollToTopObserver(target) {
    const scrollToTopBtn = document.querySelector('.js-scroll-to-top-fixed');

    const callback = function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                scrollToTopBtn.classList.remove('active');
                scrollToTopBtn.classList.add('pointer-events-none');
            } else {
                scrollToTopBtn.classList.add('active');
                scrollToTopBtn.classList.remove('pointer-events-none');
            }
        });
    };

    let options;

    if (window.innerWidth > 768) {
        options = {
            rootMargin: "0px 0px -90% 0px",
        }
    } else {
        options = {
            rootMargin: "0px 0px -90% 0px",
        }
    }

    const observer = new IntersectionObserver(callback, options);

    observer.observe(target);
}

function scrollToTopObserverNoBottomSection(target) {
    const scrollToTopBtn = document.querySelector('.js-scroll-to-top-fixed');

    const callback = function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                scrollToTopBtn.classList.remove('active');
                scrollToTopBtn.classList.add('pointer-events-none');
            } else {
                scrollToTopBtn.classList.add('active');
                scrollToTopBtn.classList.remove('pointer-events-none');
            }
        });
    }

    let options;

    options = {
        rootMargin: "0px 0px -200px 0px",
    }

    const observer = new IntersectionObserver(callback, options);

    observer.observe(target);
}

function headerTransparency() {
    let butterfly = document.querySelector('.butterfly-top');

    if (butterfly) {
        let menuContainer = document.querySelector('.main-nav-desktop');

        function menuBg(entry) {
            entry.isIntersecting ? menuContainer.classList.add('bg-opacity-0') : menuContainer.classList.remove('bg-opacity-0');
        }

        let callback = function (entries, observer) {
            entries.forEach(entry => {
                menuBg(entry);
            });
        };

        let options = {

        }

        let observer = new IntersectionObserver(callback, options);

        observer.observe(butterfly);
    }
}

export { appearObserver, heartObserver, headerTransparency, scrollToTopObserver, scrollToTopObserverNoBottomSection }