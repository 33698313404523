import { appearOnScroll, parallax, parallaxSlower, parallaxFaster } from "./scroll-animations";
import { textModal } from "./modals";
import initTabs from "./tabs";
import { appearObserver, headerTransparency, heartObserver, scrollToTopObserver } from "./intersection-observer";
import { initScrollDirectionIndicator } from "./menu-scroll-direction";
import { circleSwiper, quotesSwiper } from "./swipers";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { infiniteLoopAnimation } from "./infinite-loop-animation";
import { updateOnPageNav, updateScrollPos } from "./scroll-observer";
import { displayLocalTime } from "./clock";
import initScrollToTopBtn from "./scroll-to-top";
import { initGFormFunctions } from "./form-functions";
import initSmoothScrollLinks from "./on-page-links";
import FocusTrapper from './focus-trapper';
import cursorChange from "./cursor";
import initStickyListSection from "./sticky-list-section";
import initSplashScreen from "./splash-screen";
import { scrollToTopObserverNoBottomSection } from "./intersection-observer";

const appearingElements = document.querySelectorAll('.appear-on-scroll');
const parallaxElements = document.querySelectorAll('.parallax');
const parallaxElementsSlower = document.querySelectorAll('.parallax-slower');
const parallaxElementsFaster = document.querySelectorAll('.parallax-faster');

appearOnScroll(appearingElements);
parallax(parallaxElements);
parallaxSlower(parallaxElementsSlower);
parallaxFaster(parallaxElementsFaster);

document.querySelector('.text-modal') && textModal();

document.addEventListener('DOMContentLoaded', event => {
    /* CSS TRANSITION ON PAGE LOAD FIX */
    document.body.classList.remove("css-transitions-only-after-page-load");

    /* CHANGE BODY CLASS DEPENDING ON SCROLL DIRECTION */
    initScrollDirectionIndicator();

    /* INITIALIZE THE CIRCULAR ELEMENTS SWIPER */
    if (document.querySelector('.circle-swiper')) {
        circleSwiper();
        cursorChange();
    }

    document.querySelector('.quotes-swiper') && quotesSwiper();
    initTabs();

    /* APPEARING TEXT SECTION */
    initAppearingTextSection();

    /* MAIN NAV MENU FUNCTIONS */
    headerTransparency();
    handleMobMenu();
    updateOnPageNav();

    /* UPDATE THE NAV IN STICKY LIST SECTION */
    updateScrollPos();
    initStickyListSection();

    if (document.querySelector('.page-template-template-contacts') || document.querySelector('.post-template-default')) {
        document.querySelector('.js-scroll-to-top-fixed') && scrollToTopObserverNoBottomSection(document.querySelector('footer'));
    } else {
        document.querySelector('.js-scroll-to-top-fixed') && scrollToTopObserver(document.querySelector('.bottom-section'));
    }

    expandingParagraphs();

    /* SCROLL TO TOP BUTTON IN FOOTER */
    initScrollToTopBtn();

    /* SMOOTH SCROLLING LINKS */
    initSmoothScrollLinks();

    /* LOOPING TEXT ANIMATIONS */
    infiniteLoopAnimation();

    /* SCROLL TO BOTTOM IF FOCUS IS IN FOOTER */
    footerFocus();

    /* CUSTOM GFORMS STYLING FUNCTIONS */
    initGFormFunctions();

    /* DISPLAY LOCAL TIME OF OFFICE ON CONTACTS PAGE */
    displayLocalTime();

    initSplashScreen();

});

/* MOBILE MENU */
function handleMobMenu() {
    const menuBtn = document.querySelector('.js-open-menu-mob');
    const closeBtn = document.querySelector('.js-close-menu-mob')
    const menu = document.querySelector('.mob-menu');

    menuBtn.addEventListener('click', () => {
        openMenu();
    })

    closeBtn.addEventListener('click', () => {
        closeMenu();
    })

    function openMenu() {
        menu.classList.remove('translate-x-full', 'opacity-0');
        document.body.classList.add('overflow-hidden');
        menuBtn.setAttribute('aria-expanded', true);
        menu.setAttribute('aria-hidden', false);
        menu.toggleAttribute('inert');
        new FocusTrapper({
            el: menu,
        });
    }

    function closeMenu() {
        menu.classList.add('translate-x-full', 'opacity-0');
        document.body.classList.remove('overflow-hidden');
        menuBtn.setAttribute('aria-expanded', false);
        menu.setAttribute('aria-hidden', true);
        menu.toggleAttribute('inert');
    }
}

/* MAKE MAIN NAVBAR VISIBLE IF FOCUS IS IN IT  */
const navbarElements = document.querySelectorAll('.main-nav-desktop a');
navbarElements.forEach(element => {
    element.addEventListener('focus', () => {
        if (document.body.classList.contains('scrolling-up') || document.body.classList.contains('scrolling-down')) {
            document.body.classList.contains('scrolling-down') && document.body.classList.remove('scrolling-down');
            !document.body.classList.contains('scrolling-up') && document.body.classList.add('scrolling-up');
        }
    })
});

/* SCROLL TO FOOTER IF ANY LINK INSIDE IT HAS FOCUS */
function footerFocus() {
    const footerLinks = document.querySelectorAll('footer a');
    footerLinks.forEach(element => {
        element.addEventListener('focus', () => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        });
    });
}

/* HANDLE EXPANDING PARAGRAPHS */
function expandingParagraphs() {
    let expandBtns = document.querySelectorAll('.js-expand-text');

    expandBtns.forEach(btn => {
        btn.addEventListener('click', () => {
            btn.toggleAttribute('aria-expanded');
            let containerToExpand = btn.closest('li');
            containerToExpand.classList.toggle('open');

            if (containerToExpand.classList.contains('open')) {
                containerToExpand.style.maxHeight = containerToExpand.scrollHeight + "px";
                btn.innerHTML = "Show Less";
            } else {
                containerToExpand.style.maxHeight = '530px';
                btn.innerHTML = "Show More";
            }
        })

        btn.addEventListener('focus', () => {
            const yOffset = -200;
            const y = btn.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        });
    })
}

/* HANDLE TEXT AND GRAPHICS IN THE APPEARING TEXT SECTION */
function initAppearingTextSection() {
    let observerTargets = document.querySelectorAll('.appear-observer');
    observerTargets.forEach(target => {
        appearObserver(target);
    });

    let heartAnimation = document.querySelectorAll('.heart-icon-anim');
    heartAnimation.forEach(target => {
        heartObserver(target);
    });
}