function updateScrollPos() {
    const sectionsToTrack = document.querySelectorAll('.js-sticky-list-content');

    const options = {
        rootMargin: '-47.5%',
    }

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                /* DESKTOP */
                /* const activeNavLink = document.querySelector('.js-sticky-list-nav-link.active');
                const newActiveNavLink = document.querySelector(`.js-sticky-list-nav-link[href="#${entry.target.id}"]`);

                activeNavLink.classList.remove('active');
                newActiveNavLink.classList.add('active'); */

                /* MOBILE */
                const activeNavLinkMob = document.querySelector('.js-sticky-nav-link-mobile.active');
                const newActiveNavLinkMob = document.querySelector(`.js-sticky-nav-link-mobile[href="#${entry.target.id}"]`);

                activeNavLinkMob.classList.remove('active');
                newActiveNavLinkMob.classList.add('active');

                const containerToScroll = document.querySelector('.sticky-list-nav-mob');

                if (newActiveNavLinkMob.offsetLeft == 0) {
                    containerToScroll.scrollTo({
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    containerToScroll.scrollTo({
                        left: newActiveNavLinkMob.offsetLeft,
                        behavior: 'smooth'
                    });
                }

            }
        })
    }, options)

    sectionsToTrack.forEach(section => {
        observer.observe(section);
    });
}

function updateOnPageNav() {
    const sectionsToTrack = document.querySelectorAll('.on-page-nav-section');
    const navList = document.querySelector('.on-page-nav-list');

    if (navList) {
        const options = {
            rootMargin: '-47.5%',
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    /* DESKTOP */
                    const activeNavLink = navList.querySelector('.on-page-nav-item.active a');
                    const newActiveNavLink = navList.querySelector(`a[href="./#${entry.target.id}"]`);

                    activeNavLink && activeNavLink.parentElement.classList.remove('active');
                    newActiveNavLink.parentElement.classList.add('active');
                }
            })
        }, options)

        sectionsToTrack.forEach(section => {
            observer.observe(section);
        });
    }
}


export { updateScrollPos, updateOnPageNav };