function initScrollDirectionIndicator() {
    let prevScrollPos = window.scrollY;

    window.addEventListener("scroll", () => {
        throttle(() => {
            let scrollPos = window.scrollY;
            let scrollDirection = getScrollDirection(prevScrollPos, scrollPos, 40);
            handleBodyClass(scrollDirection);
            prevScrollPos = window.scrollY;
        }, 250);
    });

    menuFocus();
}

function getScrollDirection(prevScrollPos, scrollPos, threshold) {
    if (scrollPos > prevScrollPos + threshold) {
        return 1;
    } else if (scrollPos < prevScrollPos - threshold) {
        return -1;
    }
}

function handleBodyClass(scrollDirection) {
    if (window.scrollY < 30) {
        document.body.classList.contains('scrolling-down') && document.body.classList.remove('scrolling-down');
        document.body.classList.contains('scrolling-up') && document.body.classList.remove('scrolling-up');
    } else if (scrollDirection < 0) {
        !document.body.classList.contains('scrolling-up') && document.body.classList.add('scrolling-up');
        document.body.classList.contains('scrolling-down') && document.body.classList.remove('scrolling-down');
    } else if (scrollDirection > 0) {
        !document.body.classList.contains('scrolling-down') && document.body.classList.add('scrolling-down');
        document.body.classList.contains('scrolling-up') && document.body.classList.remove('scrolling-up');
    }
}


//initialize throttlePause variable outside throttle function
let throttlePause;

const throttle = (callback, time) => {
    //don't run the function if throttlePause is true
    if (throttlePause) return;

    //set throttlePause to true after the if condition. This allows the function to be run once
    throttlePause = true;

    //setTimeout runs the callback within the specified time
    setTimeout(() => {
        callback();

        //throttlePause is set to false once the function has been called, allowing the throttle function to loop
        throttlePause = false;
    }, time);
};

/* Make the menu appear when navigating to it by keyboard */
function menuFocus() {
    let mainNavLogo = document.querySelector('.main-nav-logo');

    mainNavLogo.addEventListener('focus', () => {
        if (document.body.classList.contains('scrolling-up') || document.body.classList.contains('scrolling-down')) {
            document.body.classList.contains('scrolling-down') && document.body.classList.remove('scrolling-down');
            !document.body.classList.contains('scrolling-up') && document.body.classList.add('scrolling-up');
        }
    })
}

export { initScrollDirectionIndicator }