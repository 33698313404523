export default function initSmoothScrollLinks() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            const id = this.getAttribute('href');
            if (id == '#main') {
                return;
            }

            const yOffset = -160;
            const element = document.querySelector(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            e.preventDefault();

            window.scrollTo({ top: y, behavior: 'smooth' });
        });
    });
}