function initTabs() {
    const tabButtons = document.querySelectorAll('.js-tab-button');

    if (tabButtons.length > 0) {
        tabButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                if (!e.target.classList.contains('active')) {
                    activateTab(e.target);
                }
            })
        })

        const btnsPrev = document.querySelectorAll('.js-tabs-prev');
        const btnsNext = document.querySelectorAll('.js-tabs-next');

        btnsPrev.forEach(btnPrev => {
            btnPrev.addEventListener('click', () => {
                let activeTabBtn = document.querySelector('.js-tab-button.active');

                if (activeTabBtn.id == activeTabBtn.parentElement.parentElement.firstElementChild.querySelector('.js-tab-button').id) {
                    let prevTab = activeTabBtn.parentElement.parentElement.lastElementChild.querySelector('.js-tab-button');
                    activateTab(prevTab);
                } else {
                    let prevTab = activeTabBtn.parentElement.previousElementSibling.querySelector('.js-tab-button');
                    activateTab(prevTab);
                };
            })
        })

        btnsNext.forEach(btnNext => {
            btnNext.addEventListener('click', () => {
                let activeTabBtn = document.querySelector('.js-tab-button.active');

                if (activeTabBtn.id == activeTabBtn.parentElement.parentElement.lastElementChild.querySelector('.js-tab-button').id) {
                    let nextTab = activeTabBtn.parentElement.parentElement.firstElementChild.querySelector('.js-tab-button');
                    activateTab(nextTab);
                } else {
                    let nextTab = activeTabBtn.parentElement.nextElementSibling.querySelector('.js-tab-button');
                    activateTab(nextTab);
                };
            })
        })
    }


    function activateTab(clickedBtn) {
        let prevTab = document.querySelector('.js-tab-button.active');
        let prevContent = document.querySelector('.js-tab-content.active');
        let tabContentContainer = document.querySelector('.tabs-content-container');

        prevTab.classList.remove('active');
        prevTab.setAttribute('aria-expanded', false);

        prevContent.classList.remove('active');
        prevContent.toggleAttribute('inert');

        clickedBtn.classList.add('active');
        clickedBtn.setAttribute('aria-expanded', true);

        let tabContent = document.querySelector(`#tab-${clickedBtn.id}`);

        tabContent.classList.add('active');
        tabContent.toggleAttribute('inert');

        /* SCROLL TO THE TAB ON MOBILE */
        if (window.innerWidth <= 768) {
            tabContentContainer.style.height = tabContent.scrollHeight + 'px';

            const containerToScroll = document.querySelector('.js-tab-list').parentElement;

            const scrollDistance = clickedBtn.parentElement.offsetLeft;

            containerToScroll.scrollTo({
                left: scrollDistance - 23,
                behavior: 'smooth'
            });

            const yOffset = -100;
            const y = containerToScroll.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({
                top: y,
                behavior: 'smooth',
            });
        }
    }

}

export default initTabs