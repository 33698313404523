import gsap from "gsap";

export default function cursorChange() {
    if (window.matchMedia("(min-width: 768px)").matches) {
        const disabledCursorOpacity = 0.3;

        function initCursor() {
            const cursorContainers = document.querySelectorAll('.cursor-container');

            cursorContainers.forEach(cursorContainer => {
                const cursor = cursorContainer.querySelector('.cursor');

                if (!cursor) {
                    return;
                }

                const areaLeft = cursorContainer.querySelector('.cursor-left');
                const areaRight = cursorContainer.querySelector('.cursor-right');
                let cursorActive = false;
          
                gsap.set(cursor, { xPercent: -50, yPercent: -50 });

                cursorContainer.addEventListener('mouseenter', (e) => {
                    cursorActive = true;
                    cursor.style.opacity = 1;
                });
                
                cursorContainer.addEventListener('mouseleave', () => {
                    cursorActive = false;
                    cursor.style.opacity = 0;
                });
    
                areaLeft.addEventListener('mouseenter', () => {
                    cursor.classList.contains('cursor-r') && cursor.classList.remove('cursor-r');
                    handleCursorOpacity(areaLeft, cursor);
                });
                
                areaRight.addEventListener('mouseenter', () => {
                    !cursor.classList.contains('cursor-r') && cursor.classList.add('cursor-r');
                    handleCursorOpacity(areaRight, cursor);
                });

                cursorContainer.addEventListener('click', e => {
                    handleCursorOpacity(e.target, cursor);
                })
    
                window.addEventListener('mousemove', (e) => {
                    if (cursorActive) {
                        gsap.to(cursor, 0, { x: e.clientX, y: e.clientY });
                    }
                });
            })
      
            let size = '1';
      
            var timestamp = 0;
            var mY = 0;
            var mX = 0;
      
            const moveCursor = (e) => {
                var now = Date.now();
                var currentmY = e.screenY;
                var currentmX = e.screenX;
                var dt = now - timestamp;
      
                var distanceY = Math.abs(currentmY - mY);
                var speedY = Math.round((distanceY / dt) * 1000);
      
                var distanceX = Math.abs(currentmX - mX);
                var speedX = Math.round((distanceX / dt) * 1000);
      
                mY = currentmY;
                mX = currentmX;
                timestamp = now;
      
                const mouseY = e.clientY;
                const mouseX = e.clientX;
            };

            
            window.addEventListener('mousemove', moveCursor);
        }
        
        document.body.classList.add('custom-cursor-activated');
        initCursor();
        
        function handleCursorOpacity(area, cursorDiv) {
            const pointerDirection = area.classList.contains('cursor-left') ? 'left' : 'right';

            if(area.classList.contains('swiper-button-disabled') && area.classList.contains(`cursor-${pointerDirection}`)) {
                if(cursorDiv.style.opacity != disabledCursorOpacity ) {
                    cursorDiv.style.opacity = disabledCursorOpacity;
                }
            } else {
                if(cursorDiv.style.opacity != 1 ) {
                    cursorDiv.style.opacity = 1;
                }
            }
        }
    }
}
