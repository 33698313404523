import Swiper, { Navigation, EffectFade, Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/autoplay";

function circleSwiper() {
    const slider = new Swiper(".circle-swiper", {
        slidesPerView: 'auto',
        draggable: true,
        speed: 700,
        centeredSlides: true,
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: false,
        modules: [Navigation],
        navigation: {
            nextEl: ".circle-swiper-next",
            prevEl: ".circle-swiper-prev",
        },
        a11y: true,
        breakpoints: {
            // when window width is >= 480px
            768: {
                centeredSlides: false,
                slidesPerGroup: 2,
            },
        }
    });
}

function quotesSwiper() {
    const slider = new Swiper(".quotes-swiper", {
        /* slidesPerView: 'auto', */
        /* draggable: true, */
        speed: 700,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        effect: 'fade',
        autoplay: {
            delay: 5000,
        },
        a11y: true,
        fadeEffect: {
            crossFade: true
        },
        modules: [Navigation, EffectFade, Autoplay],
        navigation: {
            nextEl: ".quotes-swiper-next",
            prevEl: ".quotes-swiper-prev",
        },
    });
}

export { circleSwiper, quotesSwiper }