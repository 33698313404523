

function initGFormFunctions() {
    const formWrapper = document.querySelector('.gform_wrapper');
    const form = document.querySelector('form');

    handleFormLabels();

    formWrapper && form.addEventListener('submit', (e) => {
        handleFormLabels();
    })

    styleGFormUploadButton();

    /* REMOVE QUOTATION MARKS FROM gform_required_legend */
    const formLegend = formWrapper && formWrapper.querySelector('.gform_required_legend');
    if (formLegend) {
        formLegend.innerHTML = removeQuotationMarks(formLegend.innerHTML);
    }
}

function removeQuotationMarks(stringToEdit) {
    return stringToEdit.replace(/"(<span)(.*)(span>)"/g, "$1 $2 $3");
}


/* HANDLE FORM LABELS */
function handleFormLabels() {
    if (document.querySelector('.gform_wrapper')) {
        const inputFields = document.querySelectorAll('.gform_wrapper input');

        inputFields.forEach(element => {
            let inputContainer = element.closest('.gfield');
            let inputLabel = inputContainer && inputContainer.querySelector('label');

            element.addEventListener('beforeinput', () => {
                inputLabel.style.opacity = 0.5;
            })

            /* 
            element.addEventListener('focusout', () => {
                inputLabel.style.opacity = 0;
            })
            */
        })
    }
}

function styleGFormUploadButton() {
    const uploadBtns = document.querySelectorAll('.gform_button_select_files');

    uploadBtns.forEach(btn => {
        btn.innerHTML = 'Attach a File';
    })
}

export { initGFormFunctions }