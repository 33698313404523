import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function appearOnScroll(elements) {
    elements.forEach(element => {
        ScrollTrigger.create({
            trigger: element,
            start: "top 80%",
            end: "top 70%",
            onEnter: () => {
                element.classList.add('on-screen');
            },
        });
    });
}

function parallaxFaster(elements) {
    elements.forEach(element => {
        gsap.to(element, {
            y: -500,
            duration: 1,
            scrollTrigger: {
                element,
                start: "top 10%",
                scrub: 1,
                markers: false,
            }
        })
    })
}

function parallax(elements) {
    elements.forEach(element => {
        gsap.to(element, {
            y: -150,
            duration: 1,
            scrollTrigger: {
                element,
                start: "top 10%",
                scrub: 1,
                markers: false,
            }
        })
    })
}

function parallaxSlower(elements) {
    elements.forEach(element => {
        gsap.to(element, {
            y: -100,
            duration: 3,
            scrollTrigger: {
                element,
                start: "top 10%",
                scrub: 1,
                markers: false,
            }
        })
    })
}

export { appearOnScroll, parallax, parallaxSlower, parallaxFaster }