import { gsap } from "gsap";

function infiniteLoopAnimation() {
    let outerContainer = document.querySelectorAll('.infinite-loop-container');

    outerContainer.forEach(container => {
        let textContainers = container.querySelectorAll('div');

        textContainers.forEach((textContainer, i) => {
            const spanToClone = container.querySelector('div>span');

            //  Store the width before browser adds a space
            const oldSpanWidth = spanToClone.offsetWidth;

            //  Adding a copy of the <span> to see the actual width because
            //  browser adds a space after the <span> text if followed by another <span>
            textContainer.appendChild(spanToClone.cloneNode(true));

            //  Store the new width of the first span
            const newSpanWidth = spanToClone.offsetWidth;
            const spanWidthDifference = newSpanWidth - oldSpanWidth;

            //  Find how many times the span can fit on the screen and round it up
            const cloneTimes = Math.ceil(window.innerWidth / newSpanWidth);
            const repeatTimes = cloneTimes * 2;

            for (var j = 0; j < repeatTimes - 1; j++) {
                textContainer.appendChild(spanToClone.cloneNode(true));
            }

            //  Set new width of the text container
            const newWidth = spanToClone.offsetWidth * repeatTimes - spanWidthDifference;
            textContainer.style.setProperty("width", newWidth + "px");

            //  If the loop container has multiple lines of text, make the timings vary a bit
            let animDuration = textContainers.length < 2 ? 50 : (i % 2 + 2) * 80 + i * 50;

            const fromX = -i * window.innerWidth;

            //  If there are more than one lines of text, make
            //  every next row offset by one screen width
            const toX = (-1 * repeatTimes * newSpanWidth / 2) + (-i * window.innerWidth);

            textContainer.style.setProperty('--animate-from-x', `${fromX}px`);
            textContainer.style.setProperty('--animate-to-x', `${toX}px`);
            textContainer.style.setProperty('--animation-duration', `${animDuration}s`);

            /* gsap.fromTo(textContainer, { x: fromX }, { x: toX, duration: animDuration, repeat: -1, ease: 'power0' }); */
        })
    })
}

export { infiniteLoopAnimation };